import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyCardListExtensive from '../components/CompanyCardListExtensive';

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  // Memoize the location data to avoid changing dependencies on every render
  const locationData = React.useMemo(() => {
    const { companies } = location.state || {};
    return companies || [];
  }, [location.state]);
  

  // Fetch company data when the component mounts
  useEffect(() => {
    if (locationData.length === 0) {
      navigate(-1); // Redirect to form if no data is available
    } else {
      // Set the fetched company data to state
      setCompanies(locationData);
    }
  }, [locationData, navigate]);

  if (companies.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 text-gray-800 px-4">
        <h2 className="text-2xl font-bold mb-4 text-center">
          No company data available.
        </h2>
        <button
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          onClick={() => navigate(-1)} // Navigate back to the form
        >
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 text-gray-800 px-4">
      <h2 className="text-2xl font-bold mb-4 text-center">
        That was it, just like that you have contacted the whole industry.
      </h2>
      <p className="text-lg mb-2 text-center">
        We will proceed with this email to approximately 30 companies.
      </p>
      <p className="text-lg text-center mb-6">
        Familiarize yourself with the businesses you have contacted:
      </p>
      <CompanyCardListExtensive companies={companies} />
    </div>
  );
};

export default ConfirmationPage;
