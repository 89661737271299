import React from 'react';

const FAQSection = () => {
  const faqItems = [
    { question: 'What is this app?', answer: 'This app helps you contact companies via email.' },
    { question: 'How do I use it?', answer: 'Fill out the forms and submit to contact companies.' },
    { question: 'Is my data secure?', answer: 'Yes, we comply with GDPR regulations.' },
  ];

  return (
    <div className="faq-section bg-gray-100 p-4 rounded-lg">
      <h2 className="text-xl font-bold mb-4">Frequently Asked Questions</h2>
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item mb-3">
          <h3 className="text-lg font-semibold">{item.question}</h3>
          <p>{item.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
