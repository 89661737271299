import axios from 'axios';

// Dynamically get the base URL from the environment variable
const API_URL = process.env.REACT_APP_API_URL;

const apiService = axios.create({
  baseURL: API_URL, // Base URL is determined dynamically
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiService;
