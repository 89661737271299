import React from 'react';

const Statistics = () => {
  const stats = [
    {
      title: 'Companies',
      value: '1,200+',
      description: 'Partnered companies worldwide.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-blue-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 14.5c-2.485 0-4.5-2.015-4.5-4.5S9.515 5.5 12 5.5s4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5zM12 14.5v5m0 0h-3m3 0h3"
          />
        </svg>
      ),
    },
    {
      title: 'Industries',
      value: '50+',
      description: 'Diverse industries served.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-green-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 12l3-3m0 0l3 3m-3-3v12m6-12l3-3m0 0l3 3m-3-3v12"
          />
        </svg>
      ),
    },
    {
      title: 'Emails Sent',
      value: '10,000+',
      description: 'Successful email campaigns.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-red-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v2.25M21 8.25l-9 6.75-9-6.75M21 8.25v9.75A2.25 2.25 0 0118.75 20.25H5.25A2.25 2.25 0 013 18V8.25"
          />
        </svg>
      ),
    },
  ];

  return (
    <section className="statistics py-16 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Our Impact</h2>
        <div className="flex flex-col md:flex-row justify-center items-center gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="stat-card bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center max-w-xs"
            >
              {stat.svg}
              <h3 className="text-2xl font-semibold mt-4">{stat.value}</h3>
              <p className="text-lg font-medium text-gray-700 mt-2">{stat.title}</p>
              <p className="text-gray-500 mt-1">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Statistics;
