import React, { useEffect, useState, useRef } from 'react';
import FeaturedCompanyCard from './FeaturedCompanyCard';
import apiService from '../apiService';

const FeaturedCompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    // Fetch featured companies
    const fetchFeaturedCompanies = async () => {
      try {
        const response = await apiService.get('/wildcardMailing/api/companies/?featured=true');
        setCompanies(response.data);
      } catch (error) {
        console.error('Error fetching featured companies:', error);
      }
    };

    fetchFeaturedCompanies();
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-6 lg:px-20">
        <h3 className="text-3xl font-bold text-gray-800 mb-6">Featured Companies</h3>

        <div className="relative w-full overflow-hidden">
          {/* Left Arrow */}
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md z-10"
          >
            &larr;
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className="flex overflow-x-scroll scroll-smooth space-x-4 p-4 scrollbar-hide"
          >
            {companies.length > 0 ? (
              companies.map((company) => (
                <div key={company.id} className="flex-shrink-0 w-80">
                  <FeaturedCompanyCard company={company} />
                </div>
              ))
            ) : (
              <p className="text-gray-500">No featured companies available.</p>
            )}
          </div>

          {/* Right Arrow */}
          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md z-10"
          >
            &rarr;
          </button>
        </div>
      </div>
    </section>
  );
};

export default FeaturedCompanyList;
