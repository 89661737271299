import React from 'react';

const EmailFormField = ({ label, type, value, onChange, placeholder, maxLength, tooltip }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {tooltip && (
          <span
            className="ml-2 text-blue-600 cursor-pointer"
            onClick={() => alert(tooltip)}
          >
            ℹ️
          </span>
        )}
      </label>
      {type === 'textarea' ? (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          placeholder={placeholder}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-32"
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          placeholder={placeholder}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      )}
    </div>
  );
};

export default EmailFormField;
