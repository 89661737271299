import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-4">
      <div className="flex justify-center space-x-6 text-gray-600">
        <Link
          to="/agb"
          className="hover:text-indigo-600 transition-colors font-medium"
        >
          AGB
        </Link>
        <Link
          to="/terms"
          className="hover:text-indigo-600 transition-colors font-medium"
        >
          Terms of Agreement
        </Link>
        <Link
          to="/faq"
          className="hover:text-indigo-600 transition-colors font-medium"
        >
          FAQ
        </Link>
        <Link
          to="/impressum"
          className="hover:text-indigo-600 transition-colors font-medium"
        >
          Impressum
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
