import React, { useState, useEffect } from 'react';
import ErrorMessage from './common/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import CompanyCardList from '../components/CompanyCardList';
import FormField from '../components/EmailFormField';
import KeyPointsCloud from './KeyPointsCloud';
import apiService from '../apiService';
//import ReCAPTCHA from 'react-google-recaptcha';

const Form2 = ({ data, onSubmit }) => {
  const [subject, setSubject] = useState('');
  const [email_body, setEmailBody] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [greenCompany, setGreenCompany] = useState(false);
  const [consent, setConsent] = useState(false); // New state for consent
  const [companyCards, setCompanyCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  //const recaptchaRef = React.createRef();

  const navigate = useNavigate();

  const fetchCompanyData = async (location, industry_id, greenFilter) => {
    try {
      const params = {
        location,
        industry_id,
      };
      if (greenFilter) {
        params.green_company = true;
      }

      const response = await apiService.get('/wildcardMailing/api/companies/', { params });
      setCompanyCards(response.data);
    } catch (error) {
      console.error('Error fetching company data:', error);
      setCompanyCards([]);
    }
  };

  useEffect(() => {
    if (data.location && data.industry_id) {
      fetchCompanyData(data.location, data.industry_id, greenCompany);
    }
  }, [data.location, data.industry_id, greenCompany]);

  const handleToggle = (e) => {
    setGreenCompany(e.target.checked);
  };

  const handleConsentToggle = (e) => {
    setConsent(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //ReCapcha part -- 
    //const recaptchaValue = recaptchaRef.current.getValue();
    //if (!recaptchaValue) {
    //    alert("Please verify that you are a human.");
    //    return;
    //}

    if (!consent) {
      setErrorMessage('You must agree to the terms and conditions to proceed.');
      return;
    }

    if (subject && email_body && user_email) {
      try {
        const response = await apiService.post('/wildcardMailing/api/emailforms/', {
          ...data,
          subject,
          email_body,
          user_email,
          green_company: greenCompany,
        });

        if (response.status === 201) {
          const companyPromises = response.data.sent_company_ids.map((id) =>
            apiService
              .get('/wildcardMailing/api/companies/', { params: { company_id: id } })
              .then((res) => res.data)
              .catch((err) => {
                console.error(err);
                return null; // Handle errors gracefully by returning null or another default value
              }) // Missing parenthesis was here
          );
        
          Promise.all(companyPromises).then((companies) => {
            navigate('/confirmation-page', { state: { companies: companies.flat() } });
          });
        }
         else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorData = error.response.data;
          if (errorData.error === 'You can send only one email per 24 hours.') {
            setErrorMessage(errorData.error);
          } else {
            setErrorMessage('An error occurred while submitting the form.');
          }
        } else {
          setErrorMessage('An error occurred while submitting the form.');
        }
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessage('Please fill in all fields.');
    }
  };

  return (
    <div className="flex max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <form onSubmit={handleSubmit} className="flex-1 space-y-6">
        <FormField
          label="Subject for your email"
          type="text"
          value={subject}
          onChange={setSubject}
          placeholder="Enter subject..."
          maxLength={200}
          tooltip="Additional information for the subject."
        />
        <FormField
          label="Email body (limit of 2000 characters)"
          type="textarea"
          value={email_body}
          onChange={setEmailBody}
          placeholder="Write your email..."
          maxLength={2000}
          tooltip="Additional information for the email body."
        />
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={greenCompany}
            onChange={handleToggle}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
          <label className="ml-2 block text-sm text-gray-700">Green is important</label>
        </div>

        <FormField
          label="Email"
          type="email"
          value={user_email}
          onChange={setUserEmail}
          placeholder="Enter email..."
          tooltip="Enter your email address for communication."
        />

        {/* Consent Toggle */}
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={consent}
            onChange={handleConsentToggle}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          />
          <label className="ml-2 block text-sm text-gray-700">
            I agree to the <a href="/terms" className="text-indigo-600 hover:underline">terms and conditions</a>.
          </label>
        </div>

        {errorMessage && <ErrorMessage message={errorMessage} />}

        {/* TODO: Enable when you have the key */}
        {/*<ReCAPTCHA*/}
        {/*  ref={recaptchaRef}*/}
        {/*  sitekey="YOUR_SITE_KEY" // Replace with your actual site key*/}
        {/*  size="invisible"*/}
        {/*/>*/}

        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Send Email
        </button>
      </form>

      <div className="ml-6 w-1/3">
        <CompanyCardList companies={companyCards} />
      </div>

      <KeyPointsCloud subject={subject} emailBody={email_body} industry={data.industry_id} />
    </div>
  );
};

export default Form2;
