import React, { useRef, useState, useEffect } from 'react';
import CompanyCard from './CompanyCard'; // Adjust based on your project structure

const CompanyCardList = ({ companies }) => {
  const scrollRef = useRef(null);
  const [isArrowVisible, setArrowVisible] = useState(true);
  const inactivityTimeout = useRef(null);

  const scrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ top: -300, behavior: 'smooth' });
    }
  };

  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ top: 300, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    setArrowVisible(true);
    clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(() => {
      setArrowVisible(false);
    }, 3000); // Hide arrows after 3 seconds of inactivity
  };

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(inactivityTimeout.current);
    };
  }, []);

  return (
    <div className="relative w-full h-96 overflow-hidden border border-gray-200 rounded-lg shadow-lg">
      {/* Top Arrow */}
      {isArrowVisible && (
        <button
          onClick={scrollUp}
          className="absolute left-1/2 transform -translate-x-1/2 top-2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md z-10 transition-opacity duration-300"
        >
          &uarr;
        </button>
      )}

      {/* Scrollable Container */}
      <div
        ref={scrollRef}
        className="flex flex-col overflow-y-scroll scroll-smooth space-y-4 p-4 h-full"
      >
        {companies.length > 0 ? (
          companies.map((company) => (
            <div key={company.id} className="flex-shrink-0">
              <CompanyCard company={company} />
            </div>
          ))
        ) : (
          <p className="text-gray-500">No companies found.</p>
        )}
      </div>

      {/* Bottom Arrow */}
      {isArrowVisible && (
        <button
          onClick={scrollDown}
          className="absolute left-1/2 transform -translate-x-1/2 bottom-2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md z-10 transition-opacity duration-300"
        >
          &darr;
        </button>
      )}
    </div>
  );
};

export default CompanyCardList;
