import React from 'react';

const CompanyCard = ({ company }) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md flex flex-col items-start">
      <h3 className="text-lg font-semibold text-gray-900">{company.company_name}</h3>
      <p className="text-sm text-gray-500">{company.location}</p>
      {company.green_company && (
        <span className="mt-2 inline-block px-3 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
          🌱 Green Company
        </span>
      )}
    </div>
  );
};

export default CompanyCard;
