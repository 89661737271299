import React, { useState, useEffect } from "react";

// Common key points structure
const commonKeyPoints = [
  {
    inactiveText: "Subject is longer than 15 characters",
    activeText: "Subject length is good",
    keywords: null, // This will be handled separately for subject length
  },
  {
    inactiveText: "Consider adding a greeting",
    activeText: "Proper greeting",
    keywords: ["hello", "good day", "evening"],
  },
  {
    inactiveText: "Include a closing",
    activeText: "Proper closing",
    keywords: ["good bye", "cheers", "thank you"],
  },
];

const KeyPointsCloud = ({ emailBody, subject, industry }) => {
  const [keypoints, setKeypoints] = useState([]); // Holds combined key points
  const [matchedPoints, setMatchedPoints] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // Fetch keypoints from the API based on industry
  useEffect(() => {
    const fetchKeyPoints = async () => {
      try {
        const response = await fetch(`http://127.0.0.1:8000/wildcardMailing/api/keypoint?industry_id=${industry}`);
        const data = await response.json();

        // Merge common key points with fetched key points
        const mergedKeyPoints = [
          ...commonKeyPoints,
          ...data.map((point) => ({
            inactiveText: point.text_inactive,
            activeText: point.text_active,
            keywords: point.keywords,
          })),
        ];

        setKeypoints(mergedKeyPoints);
      } catch (error) {
        console.error("Error fetching keypoints:", error);
      }
    };

    fetchKeyPoints();
  }, [industry]);

  // Process key points and match keywords in the email body
  useEffect(() => {
    const emailText = emailBody.toLowerCase();
    const updatedMatches = keypoints.map((keyPoint) => {
      // Handle keywords for each key point
      if (keyPoint.keywords && keyPoint.keywords.length > 0) {
        const matched = keyPoint.keywords.some((keyword) =>
          emailText.includes(keyword.toLowerCase()) // Case-insensitive matching
        );
        return matched ? keyPoint.activeText : keyPoint.inactiveText;
      } else if (keyPoint.inactiveText === "Subject is longer than 15 characters") {
        // Special logic for subject length
        return subject.length > 15 ? keyPoint.activeText : keyPoint.inactiveText;
      }
      return keyPoint.inactiveText; // Default to inactive text if no keywords
    });

    // Update the state with the evaluated matches
    setMatchedPoints(updatedMatches);
  }, [emailBody, subject, keypoints]); // Re-run when emailBody, subject, or keypoints changes

  const handleVisibilityToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleTooltipToggle = (state) => {
    setIsTooltipVisible(state);
  };

  // Quality Score Evaluation
  const completedCount = matchedPoints.filter((point) =>
    keypoints.some(
      (kp) => kp.activeText === point
    )
  ).length;

  const percentage = Math.round((completedCount / keypoints.length) * 100);

  return (
    <div className="mt-4 flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="relative">
          <h3 className="text-lg font-medium flex items-center">
            Quality of email
            <button
              onMouseEnter={() => handleTooltipToggle(true)}
              onMouseLeave={() => handleTooltipToggle(false)}
              className="ml-2 text-indigo-600 hover:text-indigo-800 focus:outline-none"
            >
              ℹ️
            </button>
            {isTooltipVisible && (
              <div className="absolute left-0 top-full mt-1 w-64 p-2 bg-gray-700 text-white text-xs rounded shadow-lg">
                <p>Proper email should contain following key points</p>
                <p>- Clear Subject Line</p>
                <p>- Proper Greeting</p>
                <p>- Well-Organized Content</p>
                <p>- Professional Tone</p>
                <p>- Spelling and Grammar Accuracy</p>
                <p>- Closing with a Call to Action</p>
                <p>- Friendly Closing Statement</p>
                <p>All these points lead to higher quality email, which we want to provide to our customers.</p>
              </div>
            )}
          </h3>
        </div>
        <button
          onClick={handleVisibilityToggle}
          className="ml-2 px-2 py-1 text-xs rounded bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {isVisible ? "Turn Off" : "Turn On"}
        </button>
      </div>

      {isVisible && (
        <div className="flex flex-col gap-1">
          {matchedPoints.map((text, index) => (
            <div
              key={index}
              className={`px-2 py-1 rounded text-xs font-medium ${
                text === keypoints[index]?.activeText
                  ? "bg-green-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              {text}
            </div>
          ))}
          <div className="mt-2 text-sm font-medium text-gray-700">
            Quality Score: {percentage}%
          </div>
        </div>
      )}
    </div>
  );
};

export default KeyPointsCloud;
