import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import wildcardLogo from '../../styles/logo_small.png'; // Import the image

const Header = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <header className="flex items-center justify-between px-6 py-4 bg-gray-100 shadow-md">
      {/* Left Section */}
      <div>
        <button onClick={handleHomeClick} className="focus:outline-none">
          <img src={wildcardLogo} alt="Wildcard Logo" className="h-10 w-auto" />
        </button>
      </div>

      {/* Right Section */}
      <nav className="flex space-x-6">
        <Link
          to="/about"
          className="text-gray-700 hover:text-indigo-600 font-medium transition-colors"
        >
          About
        </Link>
        <Link
          to="/for-companies"
          className="text-gray-700 hover:text-indigo-600 font-medium transition-colors"
        >
          For Companies
        </Link>
      </nav>
    </header>
  );
};

export default Header;
