import React from 'react';

const Faq = () => {
  return (
    <div> className="flex items-center justify-center min-h-screen bg-gray-100 text-gray-700">
       <h1 className="text-2xl font-semibold">This will be FAQ page</h1>
    </div>
  );
};

export default Faq;