import React from "react";

const BoostBusiness = () => {
  return (
    <section className="py-16 bg-gray-50 border-t border-gray-200">
      <div className="container mx-auto px-6 lg:px-20 text-center">
        <h3 className="text-4xl font-bold text-gray-800 mb-6">
          Looking to Boost Your Business?
        </h3>
        <p className="text-lg text-gray-700 leading-relaxed max-w-4xl mx-auto">
          Our platform helps you connect with customers who are actively searching for your services.
          With targeted leads and timely notifications, you'll have the opportunity to secure more projects that match your expertise.
          Whether you’re looking for large-scale projects or smaller tasks, we help you grow your business on your terms.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed max-w-4xl mx-auto mt-4">
          Partnering with us means gaining access to quality leads, improving customer satisfaction, and ensuring your business thrives with the right opportunities.
        </p>
        <a
          href="/companies"
          className="inline-block mt-6 px-6 py-3 bg-blue-600 text-white text-lg font-medium rounded-md shadow-md hover:bg-blue-700 transition"
        >
          Find out more
        </a>
      </div>
    </section>
  );
};

export default BoostBusiness;
