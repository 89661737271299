import React from 'react';

const HowItWorks = () => {
  const steps = [
    {
      title: 'Send a Free Email',
      description: 'Compose a single email and send it to multiple companies at once.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16 text-blue-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75L12 12.75m0 0l-9.75-6M12 12.75v8.25m0 0L9 18m3 2.25l3-2.25"
          />
        </svg>
      ),
    },
    {
      title: 'Companies Will Answer',
      description: 'Get replies directly from companies interested in your query.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16 text-green-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 10.5v-3A2.25 2.25 0 015.25 5h13.5A2.25 2.25 0 0121 7.5v3a9 9 0 11-18 0z"
          />
        </svg>
      ),
    },
    {
      title: 'Choose the Best',
      description: 'Select the best offer and continue the conversation with the company.',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-16 h-16 text-yellow-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12h15m-9-9l3 3m-3 3l3-3m6 6l3 3m-3-3l-3 3"
          />
        </svg>
      ),
    },
  ];

  return (
    <section className="how-it-works py-16 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">How It Works</h2>
        <div className="flex flex-col md:flex-row justify-center items-center gap-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="card bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center max-w-sm"
            >
              {step.svg}
              <h3 className="text-xl font-semibold mt-4">{step.title}</h3>
              <p className="text-gray-600 mt-2">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
