import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <p className="text-red-600 bg-red-100 border border-red-400 rounded px-4 py-2 text-sm mt-2">
      {message}
    </p>
  );
};

export default ErrorMessage;
