import React, { useState, useEffect } from 'react';
import ErrorMessage from './common/ErrorMessage';
import { InputField } from './common/SharedComponents';
import apiService from '../apiService';

const Form1 = ({ onSubmit }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [selectedItem1, setSelectedItem1] = useState(null);
  const [industrySuggestions, setIndustrySuggestions] = useState([]);

  const [searchTerm2, setSearchTerm2] = useState('');
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const MAX_SUGGESTIONS = 4; // Maximum number of suggestions to show

  useEffect(() => {
    const fetchData = async () => {
      try {
        const industriesResponse = await apiService.get(
          '/wildcardMailing/api/industry/'
        );
        setIndustrySuggestions(industriesResponse.data);

        const locationsResponse = await await apiService.get(
          '/wildcardMailing/api/location/'
        );
        setLocationSuggestions(locationsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedItem1 && selectedItem2) {
      onSubmit({ industry_id: selectedItem1, location: selectedItem2 });
    } else {
      setErrorMessage("Please select answers for both questions.");
    }
  };

  const handleInputChange = (e, setTerm, setItem) => {
    setTerm(e.target.value);
    setItem(null); // Reset selected item when the user types
    setErrorMessage(''); // Clear the error message when the user types
  };

  const handleSelectItem = (item, setTerm, setItem) => {
    setTerm(item);
    setItem(item);
    setErrorMessage(''); // Clear the error message when an item is selected
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto bg-white p-6 shadow-lg rounded-lg space-y-6"
    >
      <h1 className="text-2xl font-bold text-center">
        You want to reach whole industry with one message?
      </h1>
      <p className="text-center text-gray-600">
        Please select the industry you want to reach, and provide your
        approximate location. We will find the best for you.
      </p>

      <InputField
        label="Industry"
        value={searchTerm1}
        onChange={(e) => handleInputChange(e, setSearchTerm1, setSelectedItem1)}
        placeholder="I need ..."
        suggestions={industrySuggestions}
        onSelect={(item) => handleSelectItem(item, setSearchTerm1, setSelectedItem1)}
        MAX_SUGGESTIONS={MAX_SUGGESTIONS}
      />

      <InputField
        label="Location"
        value={searchTerm2}
        onChange={(e) => handleInputChange(e, setSearchTerm2, setSelectedItem2)}
        placeholder="Vienna"
        suggestions={locationSuggestions}
        onSelect={(item) => handleSelectItem(item, setSearchTerm2, setSelectedItem2)}
        MAX_SUGGESTIONS={MAX_SUGGESTIONS}
      />

      {errorMessage && (
        <ErrorMessage
          message={errorMessage}
          className="text-red-500 text-center font-medium"
        />
      )}

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none"
      >
        Continue
      </button>
    </form>
  );
};

export default Form1;
