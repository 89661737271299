import React from 'react';

const WhyAndHowItWorks = () => {
  return (
    <section className="why-and-how-it-works py-16 bg-white">
      <div className="container mx-auto px-4 text-center">
        {/* Title Section */}
        <div className="mb-16">
          <h2 className="text-4xl font-bold text-gray-800">
            It’s Not Easy to Contact Multiple Companies at Once, but It Has Huge Benefits
          </h2>
          <p className="text-gray-600 text-lg mt-4">
            Reaching out to several companies manually can be time-consuming and frustrating, but our platform simplifies the process while letting you retain full control.
          </p>
        </div>

        {/* How It Works Section */}
        <div className="mb-16 text-left">
          <h3 className="text-3xl font-semibold text-gray-800 mb-6">How This Application Works</h3>
          <p className="text-gray-700 text-lg leading-relaxed">
            You begin by writing a single email, explaining in your own words what you need. Our smart algorithm takes care of selecting up to 10 companies that best match your requirements. These companies will then reply directly to your email address. You can evaluate their responses, compare offers, and continue the conversation with the companies you like best. The entire process is seamless, straightforward, and designed to save you time and effort.
          </p>
        </div>

        {/* How We Select Companies Section */}
        <div className="mb-16 text-left bg-gray-100 p-8 rounded-lg shadow-md">
            <h3 className="text-3xl font-semibold text-gray-800 mb-6">How We Select Companies</h3>
            <p className="text-gray-700 text-lg leading-relaxed">
                Our platform prioritizes local businesses first, ensuring you get responses from companies that are accessible and relevant to your location. Using our advanced algorithm, we sort companies based on a combination of factors:
            </p>
            <ul className="list-disc list-inside text-gray-700 mt-4 space-y-2">
                <li>High response rates to ensure prompt communication.</li>
                <li>Customer satisfaction scores to connect you with trusted companies.</li>
                <li>Green company status to highlight environmentally conscious options.</li>
                <li>Overall relevance based on your specific needs and preferences.</li>
            </ul>
            <p className="text-gray-700 text-lg mt-4">
                All companies we contact are verified businesses, meeting local laws and regulations, so you can trust the quality and legitimacy of every response you receive.
            </p>
            <p className="text-gray-700 text-lg mt-4">
                This method guarantees that you receive replies from the most suitable companies, making the selection process faster and more effective.
            </p>
        </div>


        {/* Benefits of Using This App Section */}
        <div className="text-left">
          <h3 className="text-3xl font-semibold text-gray-800 mb-6">Benefits of Using This Application</h3>
          <p className="text-gray-700 text-lg leading-relaxed">
            Our platform is designed to make the process of finding and communicating with companies as effortless as possible while giving you complete control over your interactions:
          </p>
          <ul className="list-disc list-inside text-gray-700 mt-4 space-y-2">
            <li>
              You write one email in your own words, explaining exactly what you need, in natural language.
            </li>
            <li>
              We find the best companies for you based on a variety of smart selection criteria.
            </li>
            <li>
              You have full control over which companies you choose to engage with and can continue your journey with those that meet your expectations.
            </li>
          </ul>
          <p className="text-gray-700 text-lg mt-4">
            With our app, you save time, effort, and stress while maintaining total control over the process. It’s the most efficient way to connect with the companies you need.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyAndHowItWorks;
