import React from 'react';
import './app.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import About from './components/common/About';
import CompanyForm from './pages/CompanyForm';
import CompanyConfirmationPage from './pages/CompanyConfirmationPage';
import Agb from './components/common/Agb';
import Terms from './components/common/Terms';
import Faq from './components/common/Faq';
import Impressum from './components/common/Impressum';
import ConfirmationPage from './pages/ConfirmationPage';
import GdprPage from './pages/GdprPage';
import GdprConfirmationPage from './pages/GdprConfirmationPage'

const App = () => {
  return (
    <Router>
      <div className="gradient-background">
        <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/for-companies" element={<CompanyForm />} />
              <Route path="/company-confirmation" element={<CompanyConfirmationPage />} />
              <Route path="/agb" element={<Agb />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/confirmation-page" element={<ConfirmationPage />} />
              <Route path="/gdpr" element={<GdprPage />} />
              <Route path="/gdpr-confirmation" element={<GdprConfirmationPage />} />
            </Routes>
          </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
