import React from 'react';

const CompanyCardExtensive = ({ company }) => {
  return (
    <div className="border p-4 rounded-lg shadow-md bg-white mb-4">
      <h3 className="text-xl font-bold">{company.company_name}</h3>
      <p className="text-gray-700">{company.industry_id}</p>
      <p className="text-gray-700">Featured: {company.featured ? 'Yes' : 'No'}</p>
      <p className="text-gray-700">Location: {company.location}</p>
      <p className="text-gray-700">Slogan: {company.company_slogan}</p>
      <p className="text-gray-700">Short description: {company.company_short_description}</p>
      <p className="text-gray-700">Long description: {company.company_long_description}</p>
      <p className="text-gray-700">Green Company: {company.green_company ? 'Yes' : 'No'}</p>
      <p className="text-gray-700">Response Rate: {company.response_rate}%</p>
      <p className="text-gray-700">Average Response Time: {company.response_time} hours</p>
    </div>
  );
};

export default CompanyCardExtensive;
