import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../components/common/ErrorMessage';
import apiService from '../apiService';

const GdprForm = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [purpouse, setPurpouse] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(''); // Success message state

  const handleGdprFormSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message
    setSuccessMessage(''); // Reset success message

    if (!userEmail || !purpouse) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    setLoading(true); // Start loading state
    try {
      const response = await apiService.post("/wildcardMailing/api/gdpr/", {
        user_email: userEmail,
        purpouse: purpouse,
      });

      if (response.status === 201) {
        setSuccessMessage('Your GDPR request has been submitted successfully.');
        navigate('/gdpr-confirmation'); // Navigate to the confirmation page
      } else {
        setErrorMessage('There was an issue with your submission. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Error during form submission. Please try again.');
      console.error('Error during form submission:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="gdpr-form-container">
      <h2>GDPR Request Form</h2>
      <form onSubmit={handleGdprFormSubmit} className="gdpr-form">
        <div className="form-field">
          <label htmlFor="userEmail">Email Address:</label>
          <input
            id="userEmail"
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="Enter your email..."
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="purpouse">Purpouse of Request:</label>
          <select
            id="purpuose"
            value={purpouse}
            onChange={(e) => setPurpouse(e.target.value)}
            required
          >
            <option value="">Select a purpouse</option>
            <option value="deletion">Data Deletion</option>
            <option value="rectification">Data Rectification</option>
            <option value="view">Data Retrieval (View)</option>
            {/* Add more options as needed */}
          </select>
        </div>

        {errorMessage && <ErrorMessage message={errorMessage} />}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default GdprForm;
