import React, { useRef } from 'react';
import CompanyCard from './CompanyCardExtensive'; // Adjust based on your project structure

const CompanyCardListExtensive = ({ companies }) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust `300` for scroll amount
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust `300` for scroll amount
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      {/* Left Arrow */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md"
      >
        &larr;
      </button>

      {/* Scrollable Container */}
      <div
        ref={scrollRef}
        className="flex overflow-x-scroll scroll-smooth space-x-4 p-4 scrollbar-hide"
      >
        {companies.length > 0 ? (
          companies.map((company) => (
            <div key={company.id} className="flex-shrink-0 w-80">
              {/* Adjust card width as needed */}
              <CompanyCard company={company} />
            </div>
          ))
        ) : (
          <p className="text-gray-500">No companies found.</p>
        )}
      </div>

      {/* Right Arrow */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded-full shadow-md"
      >
        &rarr;
      </button>
    </div>
  );
};

export default CompanyCardListExtensive;
