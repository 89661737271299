import React from 'react';

const FeaturedCompanyCard = ({ company }) => {
  return (
    <div className="border p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-50 via-white to-blue-100 mb-4 hover:shadow-xl transition">
      <h3 className="text-xl font-bold text-blue-800">{company.company_name}</h3>
      <p className="text-gray-700">{company.industry_id}</p>
      <p className="text-gray-700">Location: {company.location}</p>
      <p className="text-gray-700">Response Rate: {company.response_rate}%</p>
      <p className="text-gray-700 truncate">{company.company_slogan}</p>
    </div>
  );
};

export default FeaturedCompanyCard;
