import React, { useState, useEffect, useRef } from 'react';
import Form1 from '../components/Form1';
import Form2 from '../components/Form2';
import ConfirmationPage from './ConfirmationPage';
import FAQSection from '../components/FAQSection';
import HowItWorks from '../components/HowItWorks';
import Statistics from '../components/Statistics';
import WhyAndHowItWorks from '../components/WhyAndHowItWorks';
import BoostBusiness from '../components/BoostBusiness'
import FeaturedCompanyList from '../components/FeaturedCompanyList';

const Home = () => {
  const [formData, setFormData] = useState(null);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [showFAQSection, setShowFAQSection] = useState(false);
  const faqRef = useRef(null);

  const handleForm1Submit = (data) => {
    setFormData(data);
  };

  const handleForm2Submit = (data) => {
    setShowConfirmationPage(true);
    // Handle the final submission with all data
    console.log('Final Form Data:', data);
  };

  useEffect(() => {
    const faqElement = faqRef.current; // Store ref value to avoid potential changes
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowFAQSection(true);
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.1 }
    );
  
    if (faqElement) {
      observer.observe(faqElement);
    }
  
    return () => {
      if (faqElement) {
        observer.unobserve(faqElement); // Use stored element for cleanup
      }
    };
  }, []);
  

  return (
    <div>
      {showConfirmationPage ? (
        <ConfirmationPage data={formData} />
      ) : formData ? (
        <Form2 data={formData} onSubmit={handleForm2Submit} />
      ) : (
        <Form1 onSubmit={handleForm1Submit} />
      )}

      <Statistics />

      <HowItWorks />

      <WhyAndHowItWorks />

      <BoostBusiness />

      <FeaturedCompanyList />
  
      {/* Invisible anchor to trigger loading FAQSection */}
      <div
        ref={faqRef}
        className="h-1 bg-transparent mt-20" // Pushes this div far below the forms
      ></div>
  
      {showFAQSection && <FAQSection />}
    </div>
  );  
};

export default Home;
