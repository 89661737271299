import React from 'react';

export const InputField = ({ label, value, onChange, placeholder, suggestions, onSelect, MAX_SUGGESTIONS }) => (
  <div>
    <label className="block text-gray-700 font-medium mb-2">{label}</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
    />
    {value && (
      <ul className="bg-white border border-gray-200 rounded-md mt-2 max-h-48 overflow-y-auto shadow-md">
        {suggestions
          .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
          .slice(0, MAX_SUGGESTIONS + 1)
          .map((item, index) => (
            <li
              key={index}
              onClick={() => onSelect(item.name)}
              className="p-2 hover:bg-blue-100 cursor-pointer"
            >
              {item.name}
            </li>
          ))}
      </ul>
    )}
  </div>
);

export const Suggestions = ({ data, searchTerm, MAX_SUGGESTIONS, onSelect }) => (
  <ul className="bg-white border border-gray-200 rounded-md mt-2 max-h-48 overflow-y-auto shadow-md">
    {data
      .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .slice(0, MAX_SUGGESTIONS + 1)
      .map((item, index) => (
        <li
          key={index}
          onClick={() => onSelect(item.name)}
          className="p-2 hover:bg-blue-100 cursor-pointer"
        >
          {item.name}
        </li>
      ))}
  </ul>
);
